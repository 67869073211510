import { JSEncrypt } from 'jsencrypt';

export const ENCRYPT_PUBLIC_KEY = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsH3ScEMwVN6DD6S9BgdU
mUV/oI/J23zoAQsIgNhOjSLsav9TOWuJ3oQ35VfNrP+LKnEX+YL1x6/QJHhxNm2l
s/ljV/VyNE7Y0KslZp/p/XAh55fQu6JYzG9chd+wHeW3SyiEplxdQZXqAeh/X7z4
VWp3jJfSZ+qDjlr8v/xA4x6Ksd/N/Wlz4B8VE15g9sZ4e+lnDmErJsMSFSDbXmMa
RV3GFGuMmQ+EPBVgps7tIj/Wd+RlBGQS0wAarI0SjzA1miwq7QUbUifQyEvmmsVX
UTM7WCGv/UGb2YbhgFMaAWT0Cz8f4PSIusNr9q/TEJP4l9oDeDqGzoLVZSPVU2Qu
NwIDAQAB`;

// RSA 加密
export const encryptRSA = (value: string) => {
  try {
    // 检查传入的值是否合法
    if (!value || typeof value !== 'string') {
      throw new Error('Invalid value. Value must be a non-empty string.');
    }

    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(ENCRYPT_PUBLIC_KEY);
    const rsaPassWord = encryptor.encrypt(value);

    // 检查加密是否成功
    if (!rsaPassWord) {
      throw new Error('Failed to encrypt value.');
    }

    return rsaPassWord;
  } catch (error: any) {
    console.error('Error occurred while encrypting value:', error.message);
    return undefined;
  }
};
