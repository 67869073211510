import './index.scss';

import { Button, Form, Input, Message, Divider } from '@arco-design/web-react';
import { useLocation, useNavigate } from '@modern-js/runtime/router';
import { fetchUserInfo, fetchUserLogin } from '@api/passport_api';
import { useContext, useLayoutEffect, useRef, useState } from 'react';
import { AUTH_STATUS_KEY, AuthorizationStatus } from '@config/constant';

import GlobalStore from '@store/globalStore';
import { useModel } from '@modern-js/runtime/model';
import useLocale from '@hooks/useLocale';
import dayjs from 'dayjs';
import { encryptRSA } from '@/utils/rsa';
import SimpleHead from '@/components/simple-head';
import { ErrorCode } from '@/api/errno';
import { validator } from '@/utils/validator';
import { useVisibilityChange } from '@/hooks/useVisibilityChange';
import { GlobalContext } from '@/routes/context';
import { LoginPageEvent, LoginType, ModuleName, PageEvent, PageNameConfig } from '@/config/track.config';

const formItemLayout = {
  wrapperCol: {
    span: 24,
  },
};

const LoginPage = () => {
  const documentVisible = useVisibilityChange();

  const [form] = Form.useForm();
  const locale = useLocale();
  const { collectEvent, setUserInfo } = useContext(GlobalContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [confirmLogin, setConfirmLogin] = useState(false);
  const [, actions] = useModel(GlobalStore);
  const timeRef = useRef(dayjs().unix());

  const pathname = location.state?.from?.pathname;
  const from = pathname || '/writing';
  const pageName = PageNameConfig.LOGIN_PAGE;
  const fromLink = pathname ? from : '';

  useLayoutEffect(() => {
    if (documentVisible) {
      timeRef.current = dayjs().unix();
      collectEvent?.([
        {
          event: PageEvent.PAGE_SHOW,
          params: {
            fromLink,
            pageName,
          },
        },
      ]);
    } else {
      onUnMounted();
    }
  }, [documentVisible]);

  const onUnMounted = () => {
    collectEvent?.([
      {
        event: PageEvent.PAGE_HIDE,
        params: {
          fromLink,
          pageName,
          duration: `${dayjs().unix() - timeRef.current}`,
        },
      },
    ]);
  };

  // 提交表单
  const onSubmit = async (values: any) => {
    setConfirmLogin(() => true);
    try {
      const params = {
        ...values,
        password: encryptRSA(values.password),
      };
      await fetchUserLogin(params);
      sessionStorage.setItem(AUTH_STATUS_KEY, AuthorizationStatus.Authenticated);
      const { userInfo = {} } = await fetchUserInfo({});
      actions.setUser(userInfo);
      setUserInfo?.({ userUniqueId: userInfo.accountId, userId: userInfo.accountId });
      collectEvent?.([
        {
          event: LoginPageEvent.CLICK_LOGIN_BUTTON,
          params: {
            pageName,
            redirectUrl: from,
            userName: userInfo.screenName || '',
            status: 'success',
            loginMethod: LoginType.ACCOUNT_PASSWORD,
            moduleName: ModuleName.LOGIN_FORM,
          },
        },
      ]);
      navigate('/writing', { replace: true });
    } catch (error: any) {
      const errorCode = error.code;
      collectEvent?.([
        {
          event: LoginPageEvent.CLICK_LOGIN_BUTTON,
          params: {
            pageName,
            errorCode,
            message: error.msg,
            status: 'fail',
            moduleName: ModuleName.LOGIN_FORM,
          },
        },
      ]);
      // 未激活
      if (ErrorCode.H_ERR_USER_UNACTIVATE === errorCode) {
        Message.error(locale.login_err_user_unactivate);
        navigate('/pwd/reset', { replace: true });

        return;
      }

      //  用户名或者密码错误
      if (ErrorCode.H_ERR_WRONG_LOGIN === errorCode) {
        Message.error(locale.login_wrong_login);
        return;
      }
      //   账号禁用
      if (ErrorCode.H_ERR_USER_BLOCKED === errorCode) {
        Message.error(locale.login_user_blocked);
        return;
      }

      //   连续输入账号密码错误
      if (ErrorCode.H_ERR_USER_PASSWORD_WRONG_LOGIN_LIMIT === errorCode) {
        Message.error(locale.login_user_password_wrong_limit);
        return;
      }

      Message.error(`code: ${error.code}, msg:${error.msg}`);
    } finally {
      setConfirmLogin(() => false);
    }
  };

  const handleForgotPassword = () => {
    collectEvent?.([
      {
        event: LoginPageEvent.CLICK_FORGOT_PASSWORD,
        params: {
          pageName,
          moduleName: ModuleName.LOGIN_FORM,
        },
      },
    ]);
    navigate('/pwd/forgot', { replace: true });
  };

  return (
    <>
      <SimpleHead />
      <div className="w-full  flex  flex-col  justify-center items-center login-wrap ">
        <div className="login">
          <div className="login-title">{locale.login_slogan}</div>
          <Divider />
          <Form {...formItemLayout} form={form} size="large" autoComplete="off" onSubmit={onSubmit} className="login-from">
            <Form.Item
              field="account"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: locale.login_form_validation_account_required,
                },
                {
                  type: 'email',
                  validator: validator.checkEmail,
                  message: locale.login_validator_email_error,
                },
              ]}
            >
              <Input placeholder={locale.login_form_account_placeholder} className="input-text" />
            </Form.Item>
            <Form.Item
              field="password"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: locale.login_form_validation_password_required,
                },
              ]}
            >
              <Input.Password placeholder={locale.login_form_password_placeholder} />
            </Form.Item>

            <Form.Item>
              <Button long type="primary" htmlType="submit" className="login-from-button" loading={confirmLogin}>
                {locale.login_form_submit_button}
              </Button>
            </Form.Item>
          </Form>
          <div className="login-links-content">
            <div className="link-button " onClick={handleForgotPassword}>
              {locale.login_form_forgot_password_button}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
